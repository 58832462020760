import "bootstrap/dist/css/bootstrap.css";
import './footer.css';

export default function Footer() {
  return (
    <div className="footer bg-footer text-light p-4">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <h5>Liên hệ</h5>
            <p>
               Địa chỉ 1: Tầng 4 - số 148 phố Chùa Láng - phường Láng Thượng - quận Đống Đa - thành phố Hà Nội<br/>
               Địa chỉ 2: 612 Phạm Văn Đồng, P.Hiệp Bình Chánh, Thành Phố Thủ Đức, Hồ Chí Minh<br/>
               Email: contact@tracuuthansohoc.top<br/>
               Hotline: 0376.196.310</p>
          </div>
          <div className="col-md-3">
            <h5>Thông tin chung</h5>
            <ul className="list-unstyled">
              <p>Về chúng tôi</p>
              <p>Chính sách bảo mật</p>
              <p>Điều khoản và điều kiện sử dụng</p>
              <p>Chính sách thanh toán</p>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Hình thức thanh toán</h5>
            <p>Ngân hàng TPBank<br/>
               Số tài khoản: 12912021999<br/>
               Chủ tài khoản: HOÀNG LINH GIANG<br/></p>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>Copyright 2024 © tracuuthansohoc.top</p>
      </div>
    </div>
  );
}
