const KarmicLessonsContent = {
  num1: (
    <div>
      <p>
        Bạn có tiềm năng không giới hạn, nhưng bạn thiếu động lực. Bạn cần phải
        tự chủ hơn, chủ động hơn. Bạn cần làm việc với mức độ quyết tâm cao hơn
        trong mọi khía cạnh cuộc sống: công việc, mối quan hệ, bất cứ điều gì
        quan trọng với bạn nhưng đòi hỏi nỗ lực để đạt được hoặc giành được. Sớm
        hay muộn, bạn sẽ phải rời khỏi vùng an toàn của mình; bạn sẽ đối mặt với
        nhiều lựa chọn khó khăn và phải ra quyết định mà không có sự hỗ trợ từ
        ai khác.
      </p>
      <p>
        Con đường cuộc sống của bạn thường xuyên gặp phải những người khó khăn
        và mạnh mẽ. Khi điều đó xảy ra, bạn sẽ học cách khẳng định vị trí của
        mình hoặc trở thành nơi họ chà đạp. Những điều này sẽ làm cho bạn mạnh
        mẽ hơn. Chúng sẽ làm nổi bật các phần động lực của cá nhân và mang lại
        cho bạn sự tự tin sâu hơn. Đừng đặt quá nhiều giá trị vào ý kiến của
        người khác về bạn. Nhiệm vụ của bạn là xây dựng một hình ảnh tự thân tốt
        hơn. Tin vào bản thân. Tin vào trực giác của bạn. Hãy cố gắng không trở
        thành người trì hoãn.
      </p>
    </div>
  ),
  num2: (
    <div>

      <p>
        Ngoại giao và tinh thần tế nhị không phải là điểm mạnh của bạn. Bạn cần
        phải làm việc để cải thiện điều này. Hãy sẵn lòng là một người đóng góp
        ẩn danh. Học cách đặt ra mục tiêu và đạt được chúng vì thành tựu hơn là
        danh dự. Bạn không cần phải nằm trong ánh đèn sân khấu suốt thời gian.
        Hãy làm việc để trở thành người tham gia nhóm tốt hơn.
      </p>
      <p>
        Bạn cần bắt đầu chú ý hơn đến cảm xúc của người khác. Bạn có xu hướng đè
        bẹp người khác. Học cách đứng lại và để họ mở rộ và phát triển. Những
        thành công trong cuộc sống của bạn sẽ đòi hỏi bạn học cách kiên nhẫn, sự
        nhượng bộ và sự hợp tác. Bạn sẽ phải học cách chia sẻ thành tựu trong
        nhiều dự án, ngay cả khi bạn là người đóng góp chính hoặc gần như là
        người đóng góp duy nhất.
      </p>
    </div>
  ),
  num3: (
    <div>
      <p>
        Bạn là người tự phê phán nặng nề nhất của chính mình. Bạn cảm thấy ánh
        sáng đè lên bạn là điều xấu hổ, chủ yếu vì bạn đã thuyết phục chính bản
        thân mình rằng mọi thứ bạn mang lại đều bị thiếu sót cơ bản. Bạn tuân
        theo mục tiêu hoàn thiện không thể đạt được và tuyệt đối, và bạn đặt tất
        cả các hành động, ý định, tài năng, khả năng và giá trị bản thân của
        mình so sánh với nó. Tóm lại, bạn cần thư giãn. Tiếp tục như vậy và bạn
        sẽ không bao giờ có niềm vui từ cuộc sống.
      </p>
      <p>
        Học cách cười. Quan trọng hơn, học cách cười VỀ BẠN CHÍNH BẠN. Điều này
        là liệu pháp tuyệt vời. Cuộc sống sẽ đặt bạn vào tình thế phải suy nghĩ
        sáng tạo và tìm ra các giải pháp ngoài hộp cho các vấn đề khác nhau. Bí
        quyết ở đây sẽ nằm ở việc thúc đẩy bạn tin tưởng vào trực giác và linh
        hồn của bạn; bạn sẽ cần phải bước ra khỏi chính con đường của mình hoặc
        tình trạng không tự tin này của bạn sẽ leo thang thành một tình thế tự
        thực hiện. Số 3 thường rất nghệ thuật. Nếu Bài học Karmic của bạn là 3,
        tuy nhiên, thành công sẽ là một con đường dài và khó khăn. Bạn sẽ đến
        đó, nhưng đó sẽ là một chặng đường dốc dài và gian nan.
      </p>
    </div>
  ),
  num4: (
    <div>
      <p>
        Bạn cảm thấy cuộc sống của bạn thiếu hướng dẫn. Bạn không nghĩ rằng bạn
        sẽ bao giờ biết bạn muốn trở thành gì khi bạn trưởng thành, mặc dù có lẽ
        bạn đã trở thành người trưởng thành trong một thời gian. Bạn cần phải
        đặt ra những quy tắc cơ bản cho cuộc sống của bạn và tuân theo chúng.
        Bạn cần đặt chân mình trên nền tảng vững chắc. Đặt ra những mục tiêu
        thực sự hoặc bạn sẽ không bao giờ cảm thấy cuộc sống của bạn cân bằng.
      </p>
      <p>
        Tìm ra điều làm bạn hạnh phúc và chỉ cần làm điều đó. Có cách để kiếm
        sống từ hầu như bất kỳ điều gì. Hãy sẵn sàng bắt đầu lại nhiều lần nếu
        cần. Sớm hay muộn bạn sẽ khám phá ra chỗ của mình. Khi bạn bắt đầu tự
        hỏi những câu hỏi như "Tôi có vấn đề gì không?" hoặc "Tại sao tôi dường
        như không bao giờ có thể ___?", bạn có xu hướng tìm kiếm câu trả lời bên
        ngoài. Vấn đề, tuy nhiên, là những thứ này bắt nguồn từ BẠN nên đó là
        nơi bạn cần tìm kiếm để tìm câu trả lời. Đúng, điều này rất không thoải
        mái, nhưng như vậy cũng là không thoải mái hơn là không thể hiện tiềm
        năng thực sự của bạn.
      </p>
    </div>
  ),
  num5: (
    <div>
      <p>
        Bạn thiếu một ý thức phiêu lưu thực sự. Có một thế giới lớn đang chờ bạn
        trải nghiệm tất cả những gì nó mang lại nhưng bạn thực sự sợ hãi sống.
        Hãy tận dụng mọi cơ hội để trải nghiệm cuộc sống. Hãy du lịch bất cứ khi
        nào bạn có thể. Thoát ra khỏi con đường thông thường và nhìn thế giới
        này từ một góc nhìn khác với người khác. Những điều bạn trải nghiệm sẽ
        khiến bạn nhận thức rõ hơn về chính mình và giúp bạn phát triển niềm say
        mê với cuộc sống.
      </p>
      <p>
        Thay đổi là một khái niệm khó khăn đối với bạn. Bạn không thích nó. Bạn
        sẽ do đó bị ép buộc phải thay đổi và thích nghi với chúng. Điều này sẽ
        đòi hỏi bạn phải linh hoạt hơn. Bạn có xu hướng rất cứng nhắc và không
        cơ hội cho những trải nghiệm mới. Học những gì bạn có thể về khái niệm
        về lòng tin. Hãy nhớ, có rất ít sự tuyệt đối trong cuộc sống. Bạn cần
        tin vào điều gì đó. Bắt đầu bằng chính bản thân bạn; đó là bước đầu tiên
        hợp lý.
      </p>
    </div>
  ),
  num6: (
    <div>
      <p>
        Bạn có vấn đề trong việc cam kết với bất cứ điều gì. Điều này đúng với
        cả mối quan hệ cũng như dự án công việc hoặc có thể thậm chí là sự
        nghiệp. Bạn xuất hiện như một người vô cảm hoặc không có cảm xúc. Bạn
        cần học cách để mọi người thấy sự phong phú của cảm xúc và trở nên có
        tính biểu đạt hơn. Có thể bạn thường cảm thấy cô đơn ngay cả khi bạn có
        người xung quanh quan tâm đến bạn và mà bạn cũng yêu thương. Bạn cần học
        cách thành thực và truyền đạt cảm xúc của mình một cách hiệu quả hơn.
        Cuộc sống sẽ mang đến cho bạn nhiều cơ hội học bài học này. Đừng tránh
        chúng, hãy đối mặt với chúng.
      </p>
      <p>
        Bạn sẽ học cách quan trọng của việc có bạn bè và bạn tâm sự và làm thế
        nào mối quan hệ cam kết lâu dài, bền vững có thể đem lại hạnh phúc. Bạn
        sẽ học về các khái niệm của sự đóng góp và sự tự hy sinh. Có rất ít điều
        khó học nhưng cũng ít điều có giá trị hơn.
      </p>
    </div>
  ),
  num7: (
    <div>
      <p>
        Bạn có xu hướng tiếp cận với một cách không quá nghiêm túc đối với một
        số điều. Cuộc sống sẽ buộc bạn phải nâng cao kiến thức và kỹ năng trong
        ít nhất một lĩnh vực cụ thể trong cuộc sống của bạn, hoặc có thể thậm
        chí là một lần một thời gian. Cuộc sống có thể khiến bạn sống trong các
        chu kỳ tương tự cho đến khi cuối cùng bạn "hiểu" bài học của mình. Bạn
        có thể chắc chắn sử dụng thái độ tự phê bình hơn một chút. Đôi khi, bạn
        thậm chí cảm thấy mình không bao giờ có lỗi gì, nhưng thực tế là chúng
        ta đều có nhược điểm và khiếm khuyết. Học cách làm việc thông qua chúng
        sẽ có ích hơn nhiều so với việc liên tục làm việc xung quanh chúng.
      </p>
      <p>
        Có nhiều thứ trong cuộc sống của bạn hơn những gì bạn thấy trên bề mặt.
        Học cách đào sâu hơn, kỳ vọng nhiều hơn và hiểu rõ hơn.
      </p>
    </div>
  ),
  num8: (
    <div>
      <p>
        Cuộc sống tài chính của bạn sẽ có những đỉnh cao và đáy sâu lớn. Bạn
        giỏi trong việc thu hút thịnh vượng. Bạn có thể thậm chí có đầu óc kinh
        doanh tốt. Tuy nhiên, những thứ này sẽ không bảo vệ bạn khỏi các vấn đề
        tiền bạc. Điều này chủ yếu do khả năng không thể đủ thận trọng của bạn
        với tiền bạc. Hơn nữa, bạn không thích nhận lời khuyên. Bạn không muốn
        người khác nói cho bạn cách thực hiện việc. Bạn có thái độ thực sự là
        "cách của tôi hoặc con đường tắt". Vấn đề là sự không thích sự quản lý
        cũng khiến bạn không nhận biết hoặc thiết lập ranh giới hợp lý. Bạn gặp
        khó khăn trong việc nhận biết giới hạn của mình và điều này khiến bạn
        trở nên như người biết tất cả.
      </p>
      <p>
        Bạn sẽ thấy mình trong các tình huống mà bài học khó khăn sẽ được học
        thông qua việc trở nên dày vò. Bạn sẽ bị ép buộc phải thừa nhận rằng bạn
        cần sự giúp đỡ, đặc biệt khi liên quan đến quản lý tiền bạc. Sự giúp đỡ
        sẽ xuất hiện và bạn sẽ phải thúc ép bản thân chấp nhận nó nếu bạn muốn
        duy trì mức sống của mình. Kiếm tiền có thể không bao giờ là vấn đề đối
        với bạn nhưng biết làm gì với nó sẽ là vấn đề.
      </p>
    </div>
  ),
  num9: (
    <div>
      <p>
        Bạn thiếu sự thông cảm hoặc lòng trắc ẩn nghiêm trọng. Ngay cả trong tâm
        trí của bạn, suy nghĩ của bạn thường xuyên tỏ ra thô lỗ hoặc không quan
        tâm. Bạn cần học cách để cho người khác có cơ hội thất bại trước mặt
        bạn. Đây chính là cách chúng ta tất cả học và phát triển. Phát triển một
        cảm giác khoan dung với giới hạn của người khác.
      </p>
      <p>
        Cuộc sống sẽ buộc bạn trở nên ít tự trung và mở tâm hơn. Nó sẽ dạy bạn
        cách nhìn ra Khung cảnh lớn và mỗi cá nhân và vật thể xung quanh bạn đều
        có giá trị. Bạn sẽ học rằng ý kiến của người khác cũng quan trọng. Bạn
        sẽ khám phá ra ý nghĩa của việc hiển thị lòng tử tế và thông cảm. Bạn sẽ
        học rằng việc giúp đỡ người khác, ngay cả trong những cách nhỏ nhặt,
        cũng mang lại sự hài lòng.
      </p>
      <p>
        Bạn sẽ thấy mình cố gắng để hòa nhập vào cộng đồng cho đến khi bạn nhận
        ra rằng bạn là một phần của nó và nó cũng là một phần của bạn. Hãy khởi
        đầu bằng cách đơn giản nói chuyện với mọi người. Tìm hiểu họ ở một mức
        độ có ý nghĩa hơn. Đừng sợ lắng nghe câu chuyện của ai đó đôi khi. Khả
        năng cao bạn sẽ học được rất nhiều về chính bản thân bạn từ những trải
        nghiệm đó.
      </p>
    </div>
  ),
  num11: (
    <div>
      <p>
        Bạn cảm thấy khó hiểu về nhiều thứ trong cuộc sống. Bạn có thể trải qua
        cảm giác thất vọng về việc không thể giải quyết những vấn đề phức tạp.
        Bài học của bạn là học cách tập trung vào việc hiểu rõ hơn về bản thân
        và xem xét cách bạn tương tác với môi trường xung quanh.
      </p>
      <p>
        Cuộc sống sẽ đưa bạn vào những tình huống đòi hỏi sự sâu sắc và sự hiểu
        biết để vượt qua. Bạn cần phải học cách lắng nghe và học hỏi từ những
        người khác. Sự kiên nhẫn và khả năng đặt câu hỏi sẽ giúp bạn tiến xa hơn
        trong việc giải quyết các thách thức phức tạp.
      </p>
    </div>
  ),
  num22: (
    <div>
      <p>
        Bạn có khả năng thấy thế giới từ một góc độ sâu sắc và có thể thấy điều
        gì đằng sau bề ngoài của mọi thứ. Bài học của bạn liên quan đến việc xây
        dựng sự kết nối giữa thế giới vật chất và tinh thần.
      </p>
      <p>
        Cuộc sống sẽ đặt bạn vào tình huống đòi hỏi sự sáng tạo và khả năng xây
        dựng. Bạn cần phải tập trung vào việc thể hiện ý tưởng và giải pháp của
        mình một cách thiết thực. Sự kiên nhẫn và tầm nhìn xa trông sẽ giúp bạn
        xây dựng những cơ hội lớn và ảnh hưởng tích cực đối với thế giới xung
        quanh.
      </p>
    </div>
  ),
};
export default KarmicLessonsContent
