import React, { useState, useEffect } from "react";
import styles from "./route.module.scss"
import NumerologyData from "../../../Components/Calculate/CalculateNumber";
import FullName from "../../../Components/Calculate/CalculateChar";
import PrimaryButton from "../../../Components/Button";
import RandomChar from "./RandomChar";
import ModalForm from "./modal";
import "bootstrap/dist/css/bootstrap.css";



const BgRoute = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const { numMain, day, month, year } =
        NumerologyData();
    const { normalizedFullName } = FullName()
    return (
        <div className={styles.bgRouteContainer}>

            <div className={styles.nameAndBirthday}>
                <p className="d-flex text-uppercase">{normalizedFullName}</p>
                <p>{day}/{month}/{year}</p>
            </div>

            <div className={styles.route}>
                <div className={styles.routeCenter}>
                    <div className={styles.numMain}>{numMain}</div>
                </div>
                <RandomChar />
            </div>

        </div>
    )
}
export default BgRoute