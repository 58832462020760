import "bootstrap/dist/css/bootstrap.css";
import "./getpass.scss";
import DemoRpPayment from "./hook/DemoRP";
import ContactForm from "./hook";


const GetPass = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <div>{ContactForm()}</div>
        </div>
      </div>
      <h3 className="pt-3 text-center pb-3">Bản xem trước của file PDF hơn 50 trang về cuộc đời bạn</h3>
      <DemoRpPayment />
    </div>
  );
};

export default GetPass;

