import Home from "../Page-Folder/Home";
import About from "../Page-Folder/About";
import Blog from "../Page-Folder/Blog";
import SendForm from "../Page-Folder/SendForm";
import Report from "../Page-Folder/Report";
import GetPass from "../Page-Folder/GetPass";
import Thankyou from "../Page-Folder/Thank";
import TermsOfUsePage from "../Page-Folder/TermsOfUsePage";
import ContentBlogNum1 from "../Page-Folder/Blog/Page/Number1";
import ContentBlogNum2 from "../Page-Folder/Blog/Page/Number2";
import ContentBlogNum3 from "../Page-Folder/Blog/Page/Number3";
import ContentBlogNum4 from "../Page-Folder/Blog/Page/Number4";
import ContentBlogNum5 from "../Page-Folder/Blog/Page/Number5";
import ContentBlogNum6 from "../Page-Folder/Blog/Page/Number6";
import ContentBlogNum7 from "../Page-Folder/Blog/Page/Number7";
import ContentBlogNum8 from "../Page-Folder/Blog/Page/Number8";
import ContentBlogNum9 from "../Page-Folder/Blog/Page/Number9";
import ContentBlogNum11 from "../Page-Folder/Blog/Page/Number11";
import ContentBlogNum22 from "../Page-Folder/Blog/Page/Number22";
import { createContext, useState } from "react";
import { Navigate } from "react-router-dom";
import Policy from "../Page-Folder/Policy";

const MyContext = createContext();

const FormValue = ({children}) => {
  const [date, setDate] = useState(() => {
    const saveDate = localStorage.getItem("savedDate");

    return saveDate ? new Date(saveDate) : null;
  });
  const [name, setName] = useState(() => {
    const saveName = localStorage.getItem("savedName");

    return saveName || null;
  });
  return (
    <MyContext.Provider
      value={{
        date,
        setDate,
        name,
        setName,
      }}
    >{children}</MyContext.Provider>
  );
};

const publishRoute = [
  { path: "/tra-cuu-so-hoc", component: Home },
  { path: "/", exact: true, component: () => <Navigate to="/tra-cuu-so-hoc" /> },
  { path: "/about", component: About },
  { path: "/blog", component: Blog },
  { path: "/send-form", component: SendForm },
  { path: "/report", component: Report },
  { path: "/getpass", component: GetPass },
  { path: "/thankyou", component: Thankyou },
  { path: "/chinh-sach-bao-mat", component: Policy },
  { path: "/dieu-khoan-su-dung", component: TermsOfUsePage },
  { path: "/than-so-hoc-1", component: ContentBlogNum1 },
  { path: "/than-so-hoc-2", component: ContentBlogNum2 },
  { path: "/than-so-hoc-3", component: ContentBlogNum3 },
  { path: "/than-so-hoc-4", component: ContentBlogNum4 },
  { path: "/than-so-hoc-5", component: ContentBlogNum5 },
  { path: "/than-so-hoc-6", component: ContentBlogNum6 },
  { path: "/than-so-hoc-7", component: ContentBlogNum7 },
  { path: "/than-so-hoc-8", component: ContentBlogNum8 },
  { path: "/than-so-hoc-9", component: ContentBlogNum9 },
  { path: "/than-so-hoc-11", component: ContentBlogNum11 },
  { path: "/than-so-hoc-22", component: ContentBlogNum22 },
];

const privateRoutes = [];
export { publishRoute, privateRoutes, MyContext,FormValue };
