const contentHeader = {
    num1:'Thần số học số 1 – Đường đời của nhà lãnh đạo bẩm sinh',
    num2:'Thần số học số 2 - Con số chủ đạo của sự cân bằng, hòa hợp và nhẹ nhàng',
    num3:'Thần số học số 3: Con số chủ đạo của mọi cuộc chơi, vô kỷ luật',
    num4:'Thần Số Học Số 4: Con Số Chủ Đạo Của Sự Cẩn Thận',
    num5:'Thần số học số 5: Đường đời của sự tự do và bất ổn',
    num6:'Thần số học số 6: Đường đời của sự yêu thương vô điều kiện',
    num7:'Thần số học số 7: Tri thức huyền bí của kẻ độc hành cô độc',
    num8:'Thần số học số 8: Con số chủ đạo, con số của tiền tài, vật chất',
    num9:'Thần Số Học Số 9: Con Số Của Chủ Nghĩa Nhân Đạo Và Trực Giác Nhạy Bén',
    num11:'Thần số học số 11: Con số chủ đạo của trực giác đi trước thời đại',
    num22:'Thần số học số 22: Con số chủ đạo của thiên tài! Chi tiết nhất',
}
const img = {
    num1:'https://numology.com/img/Life-path-1.png',
    num2:'https://numology.com/img/Life-path-2.png',
    num3:'https://numology.com/img/Life-path-3.png',
    num4:'https://numology.com/img/Life-path-4.png',
    num5:'https://numology.com/img/Life-path-5.png',
    num6:'https://numology.com/img/Life-path-6.png',
    num7:'https://numology.com/img/Life-path-7.png',
    num8:'https://numology.com/img/Life-path-8.png',
    num9:'https://numology.com/img/Life-path-9.png',
    num11:'https://numology.com/img/Life-path-11.png',
    num22:'https://numology.com/img/Life-path-22.png',
}
export{contentHeader,img}